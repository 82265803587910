import $ from 'jquery';
import 'bootstrap';
import Swiper, { Navigation, Mousewheel } from 'swiper';
import 'swiper/css';

import {
    BufferAttribute,
    BufferGeometry,
    PerspectiveCamera,
    PointLight,
    PointsMaterial,
    Scene,
    TextureLoader,
    WebGLRenderer,
    Points,
    AnimationMixer,
    Clock,
    MeshStandardMaterial,
    Mesh,
    Vector2,
    PlaneGeometry, SphereGeometry,
} from "three";
import * as TWEEN from "@tweenjs/tween.js";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {UnrealBloomPass} from "three/examples/jsm/postprocessing/UnrealBloomPass";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import formValidation from "./form";
import i18n from "./i18n";

var currentSlideIdx = 0;
var camera, scene, animationMixer;
var cameraSwitchingDesktop = [
    { x: -6, y: 0, z: 15 },
    { x: 12, y: 0, z: 13 },
    { x: 0, y: 0, z: 10 },
    { x: -2, y: 0, z: 10 },
    { x: -2, y: 0, z: 7 },
    { x: -2, y: 0, z: 7 },
    { x: -2, y: 0, z: 7 },
    { x: 0, y: -2, z: 10 },
];

var cameraSwitchingMobile = [
    { x: 0, y: 0, z: 12 },
    { x: 6, y: 0, z: 12 },
    { x: 0, y: 0, z: 12 },
    { x: -2, y: 0, z: 5 },
    { x: -2, y: 0, z: 5 },
    { x: -2, y: 0, z: 5 },
    { x: -2, y: 0, z: 5 },
    { x: 0, y: 0, z: 12 },
];

var galaxyTexturesName = ['merging', 'exploded', 'explosion', 'logo', ];
var galaxyTextures = [];

var hasStarted;

function animate(callback) {
    function loop(time) {
        callback(time);
        window.requestAnimationFrame(loop);
    }
    window.requestAnimationFrame(loop);
}

const general = {
    "mqBreakpoints": {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
    },
    moveCamera: function(fromCoords, toCoords) {
        new TWEEN.Tween(fromCoords)
            .to({ x: toCoords.x, y: toCoords.y, z: toCoords.z })
            .easing(TWEEN.Easing.Quadratic.Out)
            .onUpdate(() => {
                camera.position.set(fromCoords.x, fromCoords.y, fromCoords.z)
            })
            .start();
    },
    launchScene: function(action) {

        galaxyTextures.forEach(function(object, key) {
            if (object !== null && object.scene !== null) {
                scene.remove(object.scene);
            }
        })

        // Get array keys of the action
        const actualIndexAction = galaxyTexturesName.indexOf(action);

        if (actualIndexAction !== false) {
            animationMixer = new AnimationMixer( galaxyTextures[actualIndexAction].scene);
            animationMixer.addEventListener('loop', function() {
                let nextAction = action
                if (action === 'explosion') {nextAction = 'exploded'}
                if (action === 'merging') {nextAction = 'logo';}
                if (nextAction !== action) {
                    animationMixer.stopAllAction();
                    if (nextAction) {
                        general.launchScene(nextAction);
                    }
                }
            })

            galaxyTextures[actualIndexAction].animations.forEach( function ( clip ) {
                animationMixer.clipAction( clip ).play();
            });

            scene.add(galaxyTextures[actualIndexAction].scene);
        }
    },
    initGalaxy: function() {
        const canvas = document.querySelector('#js-galaxy');

        if (typeof canvas !== 'undefined') {

            scene = new Scene();
            const loader = new TextureLoader();
            const sizes = {
                width: window.innerWidth,
                height: window.innerHeight
            }

            // Infinite point
            const particlesGeometry = new SphereGeometry();
            const particlesCount = 2500; // 2500
            const posArray = new Float32Array(particlesCount * 3)
            for (let i = 0; i < particlesCount * 3; i++) {
                posArray[i] = (Math.random() - .5) * 50
            }

            const pointsColors = [
                0xffffff,
                0x856df2,
                // 0xdadfff
                // 0xff9696
            ]

            const cap4Square = loader.load(require("./../img/white_square.jpg"));
            const cap4Alpha= loader.load(require("./../img/galaxy_alpha.png"));
            const pointsMaterial = new PointsMaterial({
                size: 0.04,
                map: cap4Alpha,
                alphaMap: cap4Alpha,
                color: 0xffffff,
            });
            particlesGeometry.setAttribute('position', new BufferAttribute(posArray, 3));
            const particlesMesh = new Points(particlesGeometry, pointsMaterial);
            scene.add(particlesMesh)

            // Aura
            const cap4aura= loader.load(require("./../img/aura_bg_50.jpg")); 
            const geometry = new PlaneGeometry(25  , 25);  
            const material = new MeshStandardMaterial( {
                color: 0xe7343f,
                //color: 0x6f54e3,
                map: cap4aura,
                transparent: true,
                alphaMap: cap4aura,
            } );
            const plane = new Mesh( geometry, material ); 
            scene.add( plane );

            // Loading 3D scenes
            let galaxyLoaded = 0;
            const gltfloader = new GLTFLoader();

            let randomPointsColors = [];

            galaxyTexturesName.forEach(function(actionName, key) {
                gltfloader.load(
                    '/static/models/galaxy2/' + actionName +'.gltf', 
                    function (gltf) {
                        if (gltf) {
                            var logged = false;

                            // Texturised the galaxy elements
                            gltf.scene.children.forEach( function ( children ) {
                                gltf.scene.rotateX(Math.PI / 180 * 45 );

                                if (children.children.length) {
                                    children.children.forEach( function ( subchildren, idx ) {

                                        if (typeof randomPointsColors[idx] === 'undefined') {
                                            randomPointsColors[idx] = pointsColors[Math.floor(Math.random() * 2)];
                                        }

                                        if (!logged) {
                                            logged = true;
                                        }

                                        // if (actionName != 'exploded') {
                                            subchildren.material = new MeshStandardMaterial({
                                                color: randomPointsColors[idx],
                                                map: cap4Square,
                                                emissive: 0xffffff,
                                                emissiveIntensity: .5
                                            });
                                        // } else {
                                        //     subchildren.children[0].children[0].material  = new MeshStandardMaterial({
                                        //         color: randomPointsColors[idx],
                                        //         map: cap4Square,
                                        //         emissive: 0xffffff,
                                        //         emissiveIntensity: .5
                                        //     });
                                        // }
                                    });
                                }
                            } );

                            galaxyTextures[key] = gltf;
                            galaxyLoaded++;

                            if (actionName === 'merging') {
                                general.launchScene(actionName);
                            }
                        }
                    },
                )
            });

            // 4 light to create shiny stars
            // let light1 = new PointLight(0xf3cef4,.5);
            let light1 = new PointLight(0xe7343f,1);
            light1.position.set(0,-500,0);
            scene.add(light1);

            let light2 = new PointLight(0xe7343f,1);
            light2.position.set(500,0,0);
            scene.add(light2);

            let light3 = new PointLight(0xe7343f,1);
            light3.position.set(0,-500,-500);
            scene.add(light3);

            let light4 = new PointLight(0xe7343f,1);
            light4.position.set(-500,0,0);
            scene.add(light4);

            let light5 = new PointLight(0xe7343f,1);
            light5.position.set(0,0,500);
            scene.add(light5);

            // Camera
            camera = new PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
            scene.add(camera);

            /**
             * Default Rendering
             */
            const renderer = new WebGLRenderer({
                canvas: canvas,
                // powerPreference: "high-performance",
                antialias: false,
                stencil: false,
                depth: false
            })
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio( window.devicePixelRatio / 2 );

            // Add Bloom Effect as Image filter
            const composer = new EffectComposer(renderer);
            composer.addPass(new RenderPass(scene, camera));
            const bloomPass = new UnrealBloomPass(
                 new Vector2(window.innerWidth, window.innerHeight),
                 0.25, // 0.5
                 0.5, // 0.25
                 .0725 //0.25
             );
             composer.addPass(bloomPass);

            // renderer.toneMapping = ACESFilmicToneMapping;
            // renderer.toneMappingExposure = 1;
            // renderer.setClearColor(0x090520);
            renderer.setClearColor(0x221230);
            // renderer.setClearColor(0xff0000);

            // Synchrone camera position switching current slide we are
            camera.position.x = (window.innerWidth <= general.mqBreakpoints.xl) ? cameraSwitchingMobile[0].x : -6 ;
            camera.position.y = (window.innerWidth <= general.mqBreakpoints.xl) ? cameraSwitchingMobile[0].y : cameraSwitchingDesktop[0].y;
            camera.position.z = 12;

            // Clock rendering
            let clock = new Clock();
            animate((time) => {
                particlesMesh.rotation.y += 0.0005;
                if (galaxyLoaded === 4 && animationMixer) {
                    animationMixer.update(clock.getDelta());
                }
                composer.render();
                TWEEN.update(time);
            });

            // Changing camera on window change size
            window.addEventListener('resize', () => {
                // Update sizes
                sizes.width = window.innerWidth
                sizes.height = window.innerHeight

                // Update camera
                camera.aspect = sizes.width / sizes.height
                camera.updateProjectionMatrix()

                // Update renderer
                renderer.setSize(sizes.width, sizes.height)
                renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

                // Update camera
                scene.remove(camera);
                camera = new PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
                scene.add(camera);
            })
        }
    },
    initSwiper: function() {
        const swiper = new Swiper('.swiper', {
            direction: 'vertical',
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            mousewheel: {
                thresholdDelta: 30,
            },
            threshold: 30,
            modules: [Mousewheel],
        });

        swiper.on('slideChange', function() {

            const swiperIdx = swiper.activeIndex;
            const $header = $("header");
            const $pgWrapper = $("footer.footer");
            const items = $('.swiper-slide');


            if (!$header.hasClass('-minified') && swiperIdx > 0) {
                $header.addClass('-minified');
            }

            if ($header.hasClass('-minified') && (swiperIdx == 0 || swiperIdx == (items.length - 1))) {
                $header.removeClass('-minified');
            }

            $pgWrapper.toggleClass('show', swiperIdx === (items.length));
            const navigations = document.querySelector('.cap4-navigation');

            navigations.style.opacity = "0";
            if (swiperIdx !== (items.length - 1) && swiperIdx !== 0) {
                navigations.style.opacity = "1";
            }

            general.setActiveNavigation(swiperIdx);

            if (swiperIdx !== currentSlideIdx) {
                const fromCoords = {
                    x: camera.position.x,
                    y: camera.position.y,
                    z: camera.position.z
                };

                let tmpCamera = cameraSwitchingDesktop;
                if (window.innerWidth < general.mqBreakpoints.xl) {
                    tmpCamera = cameraSwitchingMobile;
                }

                const toCoords= {
                    x: tmpCamera[swiperIdx].x,
                    y: tmpCamera[swiperIdx].y,
                    z: tmpCamera[swiperIdx].z
                }

                general.moveCamera(fromCoords, toCoords);

                if (swiperIdx === 3 && currentSlideIdx === 2) {
                    general.launchScene('explosion')
                }

                if (swiperIdx === 2 && currentSlideIdx === 3) {
                    general.launchScene('merging')
                }

                if (swiperIdx === 7 && currentSlideIdx === 6) {
                    general.launchScene('merging')
                }    

                /*if (swiperIdx === 4) {
                    //console.log('initValues');   
                    //general.initValues()    
                }*/
            }

            currentSlideIdx = swiperIdx;
        });

        const btnScrollNext = document.getElementById('scroll-next');
        const navigationItems = document.querySelectorAll('.js-go-item');

        if (btnScrollNext) {
            btnScrollNext.addEventListener("click", function(e) {
                e.preventDefault();
                swiper.slideTo(1);
            });
        }

        navigationItems.forEach(navigationItem => {
            navigationItem.addEventListener('click', function(e) {
                e.preventDefault();
                if (e.target.dataset.slideTo) {
                    swiper.slideTo(e.target.dataset.slideTo);
                }
            });
        });
    },
    setActiveNavigation: function(id) {
        const navigation = document.querySelector('.cap4-navigation');

        const actives = navigation.querySelectorAll('.js-go-item.active');

        if (actives.length) {
            actives.forEach(item => {
                item.classList.remove('active');
            });
        }

        const current = navigation.querySelector('.js-go-item[data-slide-to="'+ id +'"]');
        if (current) {
            current.classList.add('active');
        }
    },
    toggleBoardCard: function() {

        const sliders = document.querySelectorAll('.js-slider');

        sliders.forEach(function(slider) {
            const navItems = slider.querySelectorAll('.js-show-item');
            const cardItems = slider.querySelectorAll('.js-show-card');
            const previousBtns = slider.querySelectorAll('.js-prev-item');
            const nextBtns = slider.querySelectorAll('.js-next-item');

            let cardSelected = null;

            if (navItems.length) {
                // Event listener on all nav items
                navItems.forEach(navigationItem => {
                    navigationItem.addEventListener('click', (navigationData) => {
                        if (cardSelected !== navigationData.target.dataset.target) {
                            if (navigationData.target.dataset.target) {
                                navItems.forEach(li => {
                                    if (li.classList.contains('--active')) {
                                        li.classList.remove('--active');
                                    }
                                });
                                cardItems.forEach(card => {
                                    if (card.classList.contains('--show')) {
                                        card.classList.remove('--show');
                                    }
                                    setTimeout(() => {
                                        if (card.getAttribute('data-id')) {
                                            if (navigationData.target.dataset.target === card.getAttribute('data-id')) {
                                                card.classList.add('--show');
                                                navigationItem.classList.add('--active');
                                                cardSelected = card.getAttribute('data-id');
                                            }
                                        }
                                    }, 100);
                                })

                                if (navigationData.target.dataset.aura) {
                                    const aura = document.querySelector('.js-slider-aura');
                                    if (typeof aura !== 'undefined') {
                                        aura.src = '/static/img/auras/' + navigationData.target.dataset.aura + '.png';
                                    }
                                }
                            }
                        }
                    });
                });

                // Initialisation, first item click trigger
                navItems[0].dispatchEvent(new Event("click"));

                // Event listener on all previous btns
                previousBtns.forEach(previousBtn => {
                    previousBtn.addEventListener('click', function(e) {
                        e.preventDefault();
                        cardItems.forEach(function(card, key) {
                            let previousCard = null;
                            if (cardSelected === card.getAttribute('data-id')) {
                                if (key !== 0) {
                                    previousCard = cardItems[key - 1];
                                } else {
                                    previousCard = cardItems[cardItems.length - 1];
                                }
                            }

                            if (previousCard) {
                                navItems.forEach(function(nav, key) {
                                    if (nav.dataset.target === previousCard.dataset.id) {
                                        nav.dispatchEvent(new Event("click"));
                                    }
                                })
                            }
                        })
                    })
                });

                // Event listener on all next btns
                nextBtns.forEach(nextBtn => {
                    nextBtn.addEventListener('click', function(e) {
                        e.preventDefault();
                        cardItems.forEach(function(card, key) {
                            let nextCard = null;
                            if (cardSelected === card.getAttribute('data-id')) {
                                if (key !== cardItems.length - 1) {
                                    nextCard = cardItems[key + 1];
                                } else {
                                    nextCard = cardItems[0];
                                }
                            }

                            if (nextCard) {
                                navItems.forEach(function(nav, key) {
                                    if (nav.dataset.target === nextCard.dataset.id) {
                                        nav.dispatchEvent(new Event("click"));
                                    }
                                })
                            }
                        })
                    })
                })
            }
        });
    },
    initDivision: function() {
        let points = document.getElementsByClassName('js-division-point');
        let cancels = document.getElementsByClassName('js-division-cancel');
        const wrapper = document.getElementsByClassName('js-division-wrapper');
        const content = document.getElementsByClassName('js-division-content');

        if (points.length) {

            let divisionEvent = 'click'
            if (window.innerWidth < general.mqBreakpoints.xl) {
                divisionEvent = 'mouseover';
            }

            // Add events listener to all links
           [...points].forEach(function(point) {
                point.addEventListener(divisionEvent, function() {
                    if (wrapper.length && content.length) {
                        wrapper[0].classList.remove('--active');
                        content[0].classList.add('--active');

                        // Triggering specific show item
                        if (typeof point.dataset.target !== 'undefined') {
                            const slideItem = document.querySelector('.js-show-item[data-target="division-item-'+ point.dataset.target +'"]');
                            slideItem.dispatchEvent(new Event(divisionEvent));
                        }
                    }
                })
            })
        }

        if (cancels.length) {
            // Cancels event listeners
            [...cancels].forEach(function(point) {
                point.addEventListener('click', function() {
                    if (wrapper.length && content.length) {
                        wrapper[0].classList.add('--active');
                        content[0].classList.remove('--active');
                    }
                })
            })
        }
    },
    sceneStarted: function(toStart) {
        hasStarted = toStart;
        if (hasStarted) {
            setTimeout(function() {
                const fromCoords = {
                    x: camera.position.x,
                    y: camera.position.y,
                    z: camera.position.z
                };

                let tmpCamera = cameraSwitchingDesktop;
                if (window.innerWidth < general.mqBreakpoints.xl) {
                    tmpCamera = cameraSwitchingMobile;
                }

                const toCoords= {
                    x: tmpCamera[currentSlideIdx].x,
                    y: tmpCamera[currentSlideIdx].y,
                    z: tmpCamera[currentSlideIdx].z
                }

                general.moveCamera(fromCoords, toCoords);
                document.querySelector('body').classList.add('--init');
            }, 2500)
        }
    },
};

export default general;

(function($) {
    $(document).ready(function() { 
        general.initSwiper();
        general.sceneStarted(false);
        general.initGalaxy();
        general.sceneStarted(true);
        general.initDivision();
        general.toggleBoardCard();
        i18n.selectToUl();
        i18n.languageSwitcherHover();
        formValidation.prepareContactForm();

        $('.home-division__point-title').each(function() {
            var text = $(this).html();
            // Remplacer "CAP4" par "CAP4 <br>" et "Intrépide" par "Intrépide <br>"
            text = text.replace('CAP4', 'CAP4 <br>').replace('Cap4', 'Cap4 <br>').replace('Intrépide', 'Intrépide <br>');
            $(this).html(text);
        });
    });
})($);