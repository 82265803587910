import $ from "jquery";
import general  from "./main";

const i18n = {
    languageSwitcherHover: function () {
        $('.js-language-switcher')
            .on('mouseenter', function () {
                if (general.mqBreakpoints['lg'] < window.innerWidth) {
                    if (!$(this).find('.dropdown-toggle').hasClass('show')) {
                        $(this).find('.dropdown-toggle').trigger('click');
                    }
                }
            })
            .on('mouseleave', function () {
                if (general.mqBreakpoints['lg'] < window.innerWidth) {
                    if ($(this).find('.dropdown-toggle').hasClass('show')) {
                        $(this).find('.dropdown-toggle').trigger('click');
                    }
                }
            });
    },
    getLanguages: function () {
        const urlPath = window.location.pathname.split('/');
        switch (urlPath[1]) {
            case 'de':
                return 'de'
            case 'fr':
                return 'fr'
            default:
                return 'en'
        }
    },
    languageBrowserHandler: function() {
        if (typeof Cookies.get('favouriteLanguage') === 'undefined') {
            let userLang = navigator.language || navigator.userLanguage;
            userLang = userLang.substr(0, 2).toLowerCase();
            Cookies.set('favouriteLanguage', 'true', { expires: 1, path: '' })

            if (userLang != 'en') {
                $('#js-select-transform').val(userLang);
                $('#js-select-transform').closest('form').submit();
            }
        }
    },
    selectToUl: function () {
        $("#js-select-transform option").each(function () {
            if (!$(this).is(':selected')) {
                $("#js-ul-transform").append('<li class="lang-switcher__item"><a href="#" class="btn btn-square" data-value="' + this.value + '">' + $(this).html() + '</a></li>');
            }
        });
        $("#js-ul-transform").on("click", "li a", function (e) {
            e.preventDefault();
            $("#js-select-transform").val($(this).attr("data-value"));
            $("#js-select-transform").closest('form').submit();
        });
    },
}
export default i18n;