import $ from "jquery";

const formValidation = {
    validateName: function() {
        var name = $("#contact-name").val();
        if (name == null || name == "") {
            $("#contact-name").removeClass("valid");
            $("#contact-name").addClass("unvalid");
            $("#nameError").addClass("show");
        } else {
            $("#contact-name").addClass("valid");
            $("#contact-name").removeClass("unvalid");
            $("#nameError").removeClass("show");
        }
    },
    validateMail: function() {
        const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailValue = $('#contact-email').val();
        if (!regexEmail.test(emailValue)) {
            $('#contact-email').addClass("unvalid");
            $('#contact-email').removeClass("valid");
            $('#emailError').addClass("show");
        } else {
            $('#contact-email').removeClass("unvalid");
            $('#contact-email').addClass("valid");
            $('#emailError').removeClass("show");
        }
    },
    validatePhone: function() {
        const phoneValue = $("#contact-phone").val();
        const regexPhone = /^\+(?:[0-9] ?){6,14}[0-9]$/;

        if (!regexPhone.test(phoneValue)) {
            $("#contact-phone").addClass("unvalid");
            $("#contact-phone").removeClass("valid");
            $("#phoneError").addClass("show");
        } else {
            $("#contact-phone").removeClass("unvalid");
            $("#contact-phone").addClass("valid");
            $("#phoneError").removeClass("show");
        }
    },
    validateMessage: function() {
        var message = $("#contact-message").val();
        if (message == null || message == "" || message.length < 9) {
            $("#contact-message").addClass("unvalid");
            $("#contact-message").removeClass("valid");
            $("#messageError").addClass("show");

        } else {
            $("#contact-message").removeClass("unvalid");
            $("#contact-message").addClass("valid");
            $("#messageError").removeClass("show");
        }
    },
    validateCaptcha: function() {
        const captcha = grecaptcha.getResponse();
        if (captcha.length > 0) {
            return true;
        } else {
            return false;
        }
    },
    form_valid: function() {
        this.validateName();
        this.validateMail();
        this.validateMessage();

        if ($("#contact-name").hasClass("valid") &&
            $("#contact-email").hasClass("valid") &&
            $("#contact-message").hasClass("valid")) {
            return true;
        }
        return false;
    },
    prepareContactForm: function() {
        $("#email-success").hide()
        $('#contact-name').keyup(formValidation.validateName);
        $('#contact-email').keyup(formValidation.validateMail);
        $('#contact-message').keyup(formValidation.validateMessage);
        $("#contact-submit").on('click', function() {
            if (!formValidation.form_valid()) {
                $("#email-error__field").show()
            } else {
                if (formValidation.validateCaptcha()) {
                    $("#email-error__field").hide()
                    $("#email-error__server").hide()
                    $("#captcha-error").removeClass("unvalid");
                    $("#captcha-error").addClass("valid");
                    $.ajax({
                        type: "POST",
                        url: "/ajax/mail",
                        data: $("#contact-form").serialize(),
                        dataType: "text",
                        success: function(data) {
                            $("#sendError").removeClass("show");
                            $("#sendSuccess").addClass("show");
                            grecaptcha.reset();
                        },
                        error: function(data) {
                            $("#sendError").addClass("show");
                            $("#sendSuccess").removeClass("show");
                            grecaptcha.reset();
                        }
                    });
                } else {
                    $("#captcha-error").removeClass("valid");
                    $("#captcha-error").addClass("unvalid");
                }
                return false;
            }
        });
    },
}

export default formValidation;